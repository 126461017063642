import { useRoutes } from 'react-router-dom';

import ROUTES from './values';

const RenderRoutes = () => {
  const element = useRoutes(ROUTES);
  return element;
};

export default RenderRoutes;
