import React from 'react';

import RenderRoutes from 'routes';
import './scss/App.scss';

function App() {
  return <RenderRoutes />;
}

export default App;
