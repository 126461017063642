import React, { useEffect } from 'react';
import { Container, Card, Image, Stack, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const Authorization = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    window.setTimeout(function () {
      window.location.href = "https://www.coinvestor.co";
    }, 5000);
  }, []);

  return (
    <Container
      fluid
      className="min-vh-100 bg-light d-flex align-items-center justify-content-center"
    >
      <Container className='my-5'>
        <Card className="shadow py-3">
          <Card.Header>
            <Container>
              <Image src="https://image.coinvestor.co/logo/LogoLightLargeBeta.png" alt="logo" style={{ maxWidth: "20rem" }} />
            </Container>
          </Card.Header>
          <Card.Body>
            <Container className="py-3">
              <Stack gap={3}>
                <div>
                  <h6 className="text-gray-300">
                    You will be redirected to new Coinvestor website.{'  '}
                    <Spinner variant="primary" animation="border" />
                  </h6>
                </div>
                <div>
                  <p className="small m-0">Click here if you are not redirected automatically</p>
                  <a href="https://www.coinvestor.co">https://www.coinvestor.co</a>
                </div>
                <div className="border-1 border-gray-200 border ">
                  <Stack gap={2}>
                    <Image src='/assets/images/popupmessage.png'></Image>
                  </Stack>
                </div>
              </Stack>
            </Container>
          </Card.Body>
        </Card>
      </Container>
    </Container>
  );
};

export default Authorization;
