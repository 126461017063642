import { Navigate, RouteObject } from 'react-router-dom';

import Authorization from 'pages/Authorization';
import paths from 'routes/paths';

const ROUTES: RouteObject[] = [
  { path: paths.main, element: <Authorization /> },
  {
    path: '*',
    element: <Navigate to={paths.main} />,
  },
];

export default ROUTES;
